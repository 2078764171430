//
// Gallery
// 


.container-filter {
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 30px;
  padding: 0;
  text-align: center;
  li {
    list-style: none;
    display: inline-block;
    a {
      display: block;
      color: $primary !important;
      border: 1px solid $primary;
      padding: 0px 15px;
      margin: 5px 5px;
      font-size: 12px;
      text-transform: capitalize;
      letter-spacing: 2px;
      cursor: pointer;
      line-height: 35px;
      -webkit-transition: all 0.6s;
      border-radius: 3px;
      &.active {    
        background-color: $primary;
        color: $white !important;
      } 
      &:hover {
        background-color: $primary !important;
         color: $white !important;
       }
    }
  }
}



.item-box {
  position: relative;
  overflow: hidden;
  display: block;
  a {
    display: inline-block;
  }
  &:hover {
    .item-mask {
      opacity: 0.8;
      visibility: visible;
      .item-caption {
        bottom: 30px;
        opacity: 1;
      }      
    }
    .item-container {
      transform: scale(1.1);
      transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
      width: 100%;
    }
  }
}



.item-container {
  transform: scale(1);
  -ms-transform: scale(1);
  -webkit-transform: scale(1);
  width: 100%;
  transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -moz-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -webkit-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
  -o-transition: all 2s cubic-bezier(0.23, 1, 0.32, 1) 0s;
}

.item-mask {
  /* background: none repeat scroll 0 0 rgba(238, 25, 66, 0.61); */
  position: absolute;
  transition: all 0.5s ease-in-out 0s;
  -moz-transition: all 0.5s ease-in-out 0s;
  -webkit-transition: all 0.5s ease-in-out 0s;
  -o-transition: all 0.5s ease-in-out 0s;
  top: 0;
  left: 0px;
  bottom: 10px;
  right: 10px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: repeating-radial-gradient(closest-side at 25px 35px, $primary 15%, $dark 40%);
  p {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 1.5px;
    font-weight: 500;
    margin: 0;
    text-align: left;
  }
  .item-caption {
    position: absolute;
    bottom: -60px;
    left: 0px;
    padding-left: 30px;
    padding-right: 30px;
    text-align: left;
    transition: all 0.5s ease-in-out 0s;
    -moz-transition: all 0.5s ease-in-out 0s;
    -webkit-transition: all 0.5s ease-in-out 0s;
    -o-transition: all 0.5s ease-in-out 0s;
    opacity: 0;
  }
}



.row.container-grid {
  margin: 0 !important;
}

