//
// Timeline
// 
.activity-scroll{
  min-height: 620px;
}
.activity {
    position: relative;
    border-left: 3px dotted $gray-200;
    margin: 20px 20px 0 22px;
    .item-info {
      margin-left: 40px;
      margin-bottom: 30px;
      h6{
        font-weight: 500;
        position: relative;
        top: -3px;
        color: $gray-700;
        font-size: 14px;
      }
      span{
          font-size: 11px;
      }
    }
    img,
    i{
      width: 36px;
      height: 36px;
      text-align: center;
      line-height: 36px;
      border-radius: 12%;
      position: absolute;
      left: -19px;
      color: $primary;
      background-color: $gray-100;
      font-size: 20px;
      margin-top: -10px;
      box-shadow: 0px 0px 0px 0.5px  $gray-100;
      transform: rotate(45deg); 
        &.icon-primary{
            color: $primary;
            background-color: lighten($primary, 30%);
        }   
        &.icon-success{
            color: $success;
            background-color: lighten($success, 48%);
        }   
        &.icon-purple{
            color: $purple;
            background-color: lighten($purple, 35%);
        }   
        &.icon-pink{
            color: $pink;
            background-color: lighten($pink, 28%);
        }  
        &.icon-info{
            color: $info;
            background-color: lighten($info, 25%);
        }  
        &.icon-warning{
            color: $warning;
            background-color: lighten($warning, 30%);
        }      
    }
   
    .mdi:before, .mdi-set{
        transform: rotate(-45deg);     
    }
}
  


/* ==============
  Timeline
===================*/


.main-timeline{
    overflow: hidden;
    position: relative;
  }
  .main-timeline:before{
    content: "";
    width: 3px;
    height: 100%;
    background: $light;
    position: absolute;
    top: 0;
    left: 50%;
  }
  .main-timeline .timeline{
    padding-right: 30px;
    position: relative;
  }
  .main-timeline .timeline:before,
  .main-timeline .timeline:after{
    content: "";
    display: block;
    width: 100%;
    clear: both;
  }
  .main-timeline .timeline:first-child:before,
  .main-timeline .timeline:last-child:before{
    content: "";
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 2px solid $light;
    background: $card;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: -3px;
  }
  .main-timeline .timeline:last-child:before{
    top: auto;
    bottom: 0;
  }
  .main-timeline .timeline-icon{
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: $card;
    border: 2px solid $light;
    box-sizing: content-box;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: -4px;
  }
  .main-timeline .timeline-icon:before{
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $secondary;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .main-timeline .year{
    display: inline-block;
    padding: 8px 20px;
    margin: 0;
    font-size: 14px;
    color: $white;
    background: $secondary;
    text-align: center;
    position: absolute;
    top: 50%;
    right: 35%;
    transform: translateY(-50%);
  }
  .main-timeline .year:before{
    content: "";
    border-right: 18px solid $secondary;
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;
    position: absolute;
    top: 0;
    left: -18px;
  }
  .main-timeline .timeline-content{
    width: 46.5%;
    padding: 20px;
    margin: 0 20px 0 0;
    background: $light;
    position: relative;
  }
  .main-timeline .timeline-content:after{
    content: "";
    border-left: 20px solid $light;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%);
  }
  .main-timeline .title{
    float: left;
    font-size: 14px;
    font-weight: 500;
    color: $gray-800;
    margin: 0 20px 20px 0;
  }
  .main-timeline .post{
    display: inline-block;
    font-size: 13px;
    color: $gray-700;
  }
  .main-timeline .description{
    font-size: 14px;
    color: $gray-700;
    line-height: 24px;
    margin: 0;
    clear: both;
  }
  .main-timeline .timeline:nth-child(2n){ padding: 0 0 0 30px; }
  .main-timeline .timeline:nth-child(2n) .year{
    right: auto;
    left: 35%;
  }
  .main-timeline .timeline:nth-child(2n) .year:before{
    border: 18px solid transparent;
    border-right: none;
    border-left: 18px solid $secondary;
    left: auto;
    right: -18px;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content{
    float: right;
    margin: 0 0 0 20px;
  }
  .main-timeline .timeline:nth-child(2n) .timeline-content:after{
    border-left: none;
    border-right: 20px solid $light;
    right: auto;
    left: -20px;
  }
  @media only screen and (max-width: 1200px){
    .main-timeline .year{ right: 30%; }
    .main-timeline .timeline:nth-child(2n) .year{ left: 30%; }
  }
  @media only screen and (max-width: 990px){
    .main-timeline .year{ right: 25%; }
    .main-timeline .timeline:nth-child(2n) .year{ left: 25%; }
  }
  @media only screen and (max-width: 767px){
    .main-timeline:before{ left: 10px; }
    .main-timeline .timeline{
        padding: 0 0 0 30px;
        margin-bottom: 20px;
    }
    .main-timeline .timeline:last-child{ margin-bottom: 0; }
    .main-timeline .timeline:first-child:before,
    .main-timeline .timeline:last-child:before{ display: none; }
    .main-timeline .timeline-icon{
        margin: 0;
        position: absolute;
        top: 7px;
        left: 0;
    }
    .main-timeline .year,
    .main-timeline .timeline:nth-child(2n) .year{
        display: block;
        font-weight: bold;
        margin: 0 0 32px 30px;
        z-index: 1;
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        transform: none;
    }
    .main-timeline .timeline:nth-child(2n) .year:before{
        border-left: none;
        border-right: 18px solid $secondary;
        right: auto;
        left: -18px;
    }
    .main-timeline .timeline-content{ padding: 20px; }
    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content{
        width: auto;
        float: none;
        margin: 0 0 0 30px;
    }
    .main-timeline .timeline-content:after,
    .main-timeline .timeline:nth-child(2n) .timeline-content:after{
        border: none;
        border-bottom: 20px solid $light;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        top: -20px;
        left: 50%;
        right: auto;
        transform: translateX(-50%);
    }
  }
  @media only screen and (max-width: 480px){
    .main-timeline .title{
        float: none;
        margin: 0;
    }
    .main-timeline .year,
    .main-timeline .timeline:nth-child(2n) .year{ margin-left: 20px; }
    .main-timeline .timeline-content,
    .main-timeline .timeline:nth-child(2n) .timeline-content{ margin-left: 10px; }
    .main-timeline .post{ margin: 5px 0; }
  }