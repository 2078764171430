//
// Modals
// 

.modal-demo {
  background-color: $white;
  width: 600px;
  border-radius: 4px;
  display: none;
  position: relative;
  .close {
      position: absolute;
      top: 15px;
      right: 25px;
      color: $gray-200;
    }
}

.modal-content{
  background-color: $gray-100;
  border-radius: 0;
  .modal-header,.modal-footer{
    border-color: $gray-300;
    background-color:$gray-100;
    .modal-title{
      color: $gray-800;
    }
    .close{
      color: $gray-800;
    }
  }
  .modal-body p,h4{
    color: $gray-500;
  }
  .modal-title.custom-title{
    color: lighten($gray-500, 80%);
  }
}

#custom-modal{
  .close{
    color: $gray-800;
  }
}
.custom-modal-title {
  padding: 15px 25px 15px 25px;
  line-height: 22px;
  font-size: 18px;
  background-color: $gray-200;
  color: $gray-800;
  text-align: left;
  margin: 0px;
}

.custom-modal-text {
  padding: 20px;
  color: $gray-500;
}

.custombox-modal-flash .close, 
.custombox-modal-rotatedown .close {
  top: 20px;
  z-index: 9999;

}

#session-timeout-dialog .modal-header {
  display: inline-block;
}