//
// Progress
// 


.progress{
  background-color: $gray-200;
  height: 14px;
  .progress-bar {
    background-color: $primary;
  }
}



/* Progressbar Vertical */

.progress-vertical {
  min-height: 250px;
  height: 250px;
  width: 10px;
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.progress-vertical .progress-bar {
  width: 100%;
}

.progress-vertical-bottom {
  min-height: 250px;
  height: 250px;
  position: relative;
  width: 10px;
  display: inline-block;
  margin-bottom: 0;
  margin-right: 20px;
}

.progress-vertical-bottom .progress-bar {
  width: 100%;
  position: absolute;
  bottom: 0;
}

.progress-vertical.progress-sm,
.progress-vertical-bottom.progress-sm {
  width: 5px !important;
}

.progress-vertical.progress-sm .progress-bar,
.progress-vertical-bottom.progress-sm .progress-bar {
  font-size: 8px;
  line-height: 5px;
}

.progress-vertical.progress-md,
.progress-vertical-bottom.progress-md {
  width: 15px !important;
}

.progress-vertical.progress-md .progress-bar,
.progress-vertical-bottom.progress-md .progress-bar {
  font-size: 10.8px;
  line-height: 14.4px;
}

.progress-vertical.progress-lg,
.progress-vertical-bottom.progress-lg {
  width: 20px !important;
}

.progress-vertical.progress-lg .progress-bar,
.progress-vertical-bottom.progress-lg .progress-bar {
  font-size: 12px;
  line-height: 20px;
}

.progress-icon-spin{
  font-size: 36px;
}