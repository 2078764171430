//
// Footer
//

.footer {
  border-top: 1px solid $gray-300;
  bottom: 0;
  padding: 20px;
  position: absolute;
  right: 0;
  left: 0;
  color: $gray-600;
}