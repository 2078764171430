//
// Table
// 

.table{
  th {
    font-weight: 500;
    border-top: 1px solid $table-border-color;
  }
  td{
    border-top: 1px solid $table-border-color;
  }
  thead {
    th {
      border-bottom: 2px solid $table-border-color;
    }
  }
}

  
  .table-bordered {
    border: 1px solid $table-border-color;
      thead {
          background-color: $light;
          td,
          th {
              font-weight: 500;
          }
      }
      td,th{
        border: 1px solid $table-border-color;
      }
  }
  
  .table-striped {
      tbody {
          tr:nth-of-type(odd) {
              background-color: transparent;
          }
          tr:nth-of-type(even) {
              background-color: $light;
          }
      }
  }

  
  .table {
    color: $table;
      thead tr th {
          font-weight: 500;
          color: $gray-700;
          font-size: 13px;
      }
      td {
          vertical-align: middle;          
      }

      .thead-light {
        th {
          color: $gray-700;
          background-color: $gray-200;
          border-color: $table-border-color;
      }
      }
  }
  
  .table-hover tbody tr:hover {
      background-color: $gray-200;
  }
  
  .table-dark {
      thead tr th {
          background-color: $light;
          border-top-color: transparent;
      }
      tbody>tr>td,
      th {
          color: $gray-200;
          background-color: $dark;
      }
  }

  .dataTables_wrapper{
      &.container-fluid{
        padding: 0;
    }
  }


  /*===Datatable===*/
  .table{
    td {
      vertical-align: middle;
      &.highlight {
        font-weight: bold;
        color: $info;
      }
      &.details-control {
        background: url('../images/open.png') no-repeat center center;
        cursor: pointer;
      }
    }
    tr.shown td.details-control {
      background: url('../images/close.png') no-repeat center center;
    }
  }

 

  
  /*== Table Responsive ==*/
  
  .table-rep-plugin {
      .btn-toolbar {
          display: block;
      }
      .btn-default {
          border-color: rgba($primary, 0.3);
          background-color: rgba($primary, 0.2);
          color: $primary;
          margin-right: 5px;
          &:focus {
              color: $primary;
          }
      }
      .btn-default.btn-primary:active,
      .btn-default.btn-primary:hover,
      .btn-default.btn-primary:focus {
          border-color: rgba($primary, 0.3) !important;
          background-color: rgba($primary, 0.2) !important;
          color: $primary;
      }
      tbody th {
          font-size: 14px;
          font-weight: normal;
      }

      table.focus-on tbody tr.focused th, 
      table.focus-on tbody tr.focused td {
        background-color: $primary;
        color: $white;
    }
  }
  
  /* Sticky Header */
  .fixed-solution .sticky-table-header {
    top: $topbar-height !important;
  }

/*====Footable table====*/

.dropdown-menu-right {
    right: 0;
    left: auto;
    }
    .open>.dropdown-menu {
      display: block;
  }
  .table.footable-details {
    color: $gray-700;
  }
  .footable-filtering-external ul.dropdown-menu>li>a.custom-control input[type=checkbox], 
  table.footable>thead>tr.footable-filtering>th ul.dropdown-menu>li>a.custom-control input[type=checkbox] {
    position: absolute;
    margin-left: -20px;
    margin-top: 5px;
  }
  
  .footable-filtering-external ul.dropdown-menu>li>a.custom-control>label, 
  table.footable>thead>tr.footable-filtering>th ul.dropdown-menu>li>a.custom-control>label {
    display: block;
    padding-left: 30px;
  }
  td.footable-sortable > span.fooicon, 
  th.footable-sortable > span.fooicon { 
    right: auto !important; 
    padding-left: 5px; 
  }
  
  
  /*====jsgrid table====*/
  
  .jsgrid {
    height: auto !important;
    th{
      font-weight: 500;
    }
  }
  .jsgrid-edit-row > .jsgrid-cell, 
  .jsgrid-filter-row > .jsgrid-cell, 
  .jsgrid-grid-body, .jsgrid-grid-header, 
  .jsgrid-header-row > .jsgrid-header-cell, 
  .jsgrid-insert-row > .jsgrid-cell {
    background: inherit;
    border-color: $table-border-color;
    height: auto !important;
    overflow: auto;
  }
  .jsgrid-cell {
    padding: 0.75rem;
  }
  .jsgrid-alt-row>.jsgrid-cell {
    background: transparent;
  }
  .jsgrid-cell input,
  .jsgrid-cell select{
      border:1px solid $table-border-color;
      box-shadow: none;
      outline: 0 none;
      padding: 8px;
  }
  .jsgrid-header-row{
    font-weight: 300 !important;
    color: $gray-700;
    background-color: $light;
  }
  .jsgrid-selected-row>.jsgrid-cell {
    background: $gray-200;
    border-color: $table-border-color;
  }
  .jsgrid-align-right{
    text-align: center;
  }

  .jsgrid-header-sortable{
      text-align: center;
  }

  .jsgrid-pager-container{
    margin-top: 16px;
  }

  .jsgrid .jsgrid-button {
    width: 22px;
    height: 22px;
    border: none;
    cursor: pointer;
    background-image: url('../plugins/jsgrid/jsgrid.png');
    background-repeat: no-repeat;
    background-color: transparent;
}


div.dataTables_wrapper div.dataTables_paginate ul.pagination{
  margin-top: 16px;
}
div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:first-child {
  align-self: center;
}