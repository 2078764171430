//
// Avatar
// 

.img-group{
  display: inline-block;
  .avatar-badge{
    position: absolute;
    right: 1px;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    box-shadow: 0 0 0 2px $gray-400;
    z-index: 2;
  }
  .user-avatar+.user-avatar-group {
    margin-left: -20px;
  }
  .user-avatar {
    position: relative;
    margin-bottom: 0;
    display: inline-block;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    &:focus,&:hover{
      z-index: 2;
    }
    .online {
      background: $success;
    }
    .offline{
      background: $danger;
    }
    img{
      position: absolute;
      top: 0;
      left: 0;
      width: 40px;
      height: 40px;
      box-shadow: 0 0 0 2px $gray-400;
    }
  }
}
.avatar-box{
  position: relative;
  display: inline-block;
  .avatar-title {
    display: flex;
    width: 100%;
    height: 100%;
    color: $gray-100;
    align-items: center;
    justify-content: center;
  }
}
