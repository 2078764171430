//
// Text Color
// 

.text-custom {
  color: $primary !important;
}

.text-white {
  color: $white !important;
}

.text-danger {
  color: $danger !important;
}

.text-muted {
  color: $muted !important;
}

.text-primary {
  color: $primary !important;
}

.text-secondary {
  color: $secondary !important;
}

.text-warning {
  color: $warning !important;
}


.text-blue {
  color: $blue !important;
}

.text-success {
  color: $success !important;
}

.text-info {
  color: $info !important;
}

.text-pink {
  color: $pink !important;
}

.text-purple {
  color: $purple !important;
}

.text-light {
  color: $light !important;
}

.text-dark {
  color: $dark !important;
}
