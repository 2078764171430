//
// Background-color
// 

.bg-primary {
  background-color: $primary !important;
}

.bg-secondary {
  background-color: $secondary !important;
}

.bg-success {
  background-color: $success !important;
}

.bg-warning {
  background-color: $warning !important;
}

.bg-info {
  background-color: $info !important;
}

.bg-danger {
  background-color: $danger !important;
}

.bg-dark {
  background-color: $dark !important;
}

.bg-light {
  background-color: $light !important;
}

.bg-pink {
  background-color: $pink !important;
}

.bg-purple {
  background-color: $purple !important;
}

.bg-blue {
  background-color: $blue !important;
}
// bg-soft

.bg-soft-primary{
  background-color: rgba($primary, 0.15) !important;
  color: $primary !important;
}

.bg-soft-secondary{
  background-color: rgba($secondary, 0.15) !important;
  color: $secondary !important;
}

.bg-soft-success{
  background-color: rgba($success, 0.15) !important;
  color: $success !important;
}

.bg-soft-warning{
  background-color: rgba($warning, 0.15) !important;
  color: $warning !important;
}

.bg-soft-info{
  background-color: rgba($info, 0.15) !important;
  color: $info !important;
}

.bg-soft-danger{
  background-color: rgba($danger, 0.15) !important;
  color: $danger !important;
}

.bg-soft-pink{
  background-color: rgba($pink, 0.15) !important;
  color: $pink !important;
}

.bg-soft-purple{
  background-color: rgba($purple, 0.15) !important;
  color: $purple !important;
}

.bg-soft-blue{
  background-color: rgba($blue, 0.15) !important;
  color: $blue !important;
}

.bg-soft-dark{
  background-color: rgba($dark, 0.15) !important;
  color: $dark !important;
}


.badge{
  box-shadow:none;
  &.badge-soft-primary{
    background-color: rgba($primary, 0.15) !important;
    color: $primary !important;
    box-shadow: 0px 0px 13px 0px rgba($primary,0.05);
  }
  
  &.badge-soft-secondary{
    background-color: rgba($secondary, 0.15) !important;
    color: $secondary !important;
    box-shadow: 0px 0px 13px 0px rgba($secondary,0.05);
  }
  
  &.badge-soft-success{
    background-color: rgba($success, 0.15) !important;
    color: $success !important;
    box-shadow: 0px 0px 13px 0px rgba($success,0.05);
  }
  
  &.badge-soft-warning{
    background-color: rgba($warning, 0.15) !important;
    color: $warning !important;
    box-shadow: 0px 0px 13px 0px rgba($warning,0.05);
  }
  
  &.badge-soft-info{
    background-color: rgba($info, 0.15) !important;
    color: $info !important;
    box-shadow: 0px 0px 13px 0px rgba($info,0.05);
  }
  
  &.badge-soft-danger{
    background-color: rgba($danger, 0.15) !important;
    color: $danger !important;
    box-shadow: 0px 0px 13px 0px rgba($danger,0.05);
  }
  
  &.badge-soft-pink{
    background-color: rgba($pink, 0.15) !important;
    color: $pink !important;
    box-shadow: 0px 0px 13px 0px rgba($pink,0.05);
  }
  
  &.badge-soft-purple{
    background-color: rgba($purple, 0.15) !important;
    color: $purple !important;
    box-shadow: 0px 0px 13px 0px rgba($purple,0.05);
  }
  
  &.badge-soft-dark{
    background-color: rgba($dark, 0.15) !important;
    color: $dark !important;
    box-shadow: 0px 0px 13px 0px rgba($dark,0.05);
  }
}
