//
// Justgage
// 



.gauge {
  width: 100%;
  height: 100%;
}

.size-1{
  width: 150px;
  height: 100px;
  margin: 30px auto 0 auto;
  text-align: center;
  border: 1px solid $gray-200;
  padding: 5px;
}

.size-2{
  width: 100px;
  height: 150px;
  margin: 30px auto 0 auto;
  text-align: center;
  border: 1px solid $gray-200;
  padding: 5px;
}

.size-3{
  width: 150px;
  height: 150px;
  margin: 30px auto 0 auto;
  text-align: center;
  border: 1px solid $gray-200;
  padding: 5px;
}